import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { Field, useForm } from 'react-final-form';
import { OperatorsSelect } from '@/tools/OperatorsSelect.molecule';
import { getFormFieldProps, getValidationFunction } from '@/formbuilder/formbuilder.utilities';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';

export interface OperatorSelectIF extends ValidatingFormComponent<any> {
  component: 'OperatorSelectFormComponent';
  operators: any[];
  disabled?: boolean;
  onChange: (entryCondition: any) => void;
  formattedOptions?: boolean;
  placeholder?: string;
}

export const OperatorSelectFormComponent: React.FunctionComponent<OperatorSelectIF> = (props) => {
  const { name, validation, extendValidation, testId, extraClassNames } = props;
  const defaultValidation = _.isEmpty;
  const appliedValidation = getValidationFunction(defaultValidation, extendValidation, validation);
  const formState = useForm().getState();

  return (
    <div className={classNames('mb8', extraClassNames)} data-testid={testId}>
      <Field name={name} validate={appliedValidation}>
        {({ input, meta }) => (
          <OperatorsSelect
            {...getFormFieldProps(formState, input, meta, props)}
            insideModal={true}
            onChange={(operator) => {
              props.onChange(operator);
              input.onChange(operator?.value);
            }}
            appendToBody={true}
          />
        )}
      </Field>
    </div>
  );
};
