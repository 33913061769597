// @ts-strict-ignore
import React from 'react';
import { ValidatingFormComponent } from '@/formbuilder/formBuilder.constants';
import moment from 'moment-timezone';
import { Field, useForm } from 'react-final-form';
import { getFormFieldProps, getValidationFunction } from '@/formbuilder/formbuilder.utilities';
import { Timezone } from '@/datetime/timezone.service';
import { DateTimeEntry } from '@/core/DateTimeEntry.atom';
import { FormFieldWrapper } from '@/formbuilder/FormFieldWrapper';
import _ from 'lodash';

export interface DateTimeEntryIF extends ValidatingFormComponent<moment.Moment> {
  component: 'DateTimeEntryFormComponent';
  hideTimezone?: boolean;
  fieldIdentifier?: string;
  timezone: Timezone;
  trackCategory?: string;
  trackAction?: string;
  trackInformation?: string;
  dateFormat?: string;
}

export const DateTimeEntryFormComponent: React.FunctionComponent<DateTimeEntryIF> = (props) => {
  const {
    label,
    name,
    testId = 'dateTimeEntry',
    extraClassNames,
    hideTimezone,
    fieldIdentifier,
    timezone,
    customErrorText,
    extendValidation,
    validation,
    trackCategory,
    trackAction,
    trackInformation,
    dateFormat,
  } = props;

  const formState = useForm().getState();
  const showError = _.has(formState.errors, name) && _.has(formState.dirtyFields, name);

  const defaultValidation = (value: moment.Moment) => !value;
  const appliedValidation = getValidationFunction(defaultValidation, extendValidation, validation);

  return (
    <FormFieldWrapper
      testId={testId}
      label={label}
      extraClassNames={extraClassNames}
      showError={showError}
      customErrorText={customErrorText}>
      <Field name={name} validate={appliedValidation}>
        {({ input, meta }) => {
          const { value, onChange } = getFormFieldProps(formState, input, meta, props);

          return (
            <DateTimeEntry
              testId={`${testId}Field`}
              extraClassName="specRange specRangeStart width-115"
              textExtraClassNames="sq-text-primary flexRowContainer"
              date={value}
              updateDate={onChange}
              dateFormat={dateFormat}
              timezone={timezone}
              inputExtraClassNames="width-100"
              hideTimezone={hideTimezone}
              fieldIdentifier={fieldIdentifier}
              trackCategory={trackCategory}
              trackAction={trackAction}
              trackInformation={trackInformation}
            />
          );
        }}
      </Field>
    </FormFieldWrapper>
  );
};
